<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" default-href="/sale/account"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t('update_user_profile') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <update-profile-form />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import UpdateProfileForm from './components/NewUpdateForm.vue';

export default defineComponent({
  name: 'UpdateProfile',
  components: {
    UpdateProfileForm
  }
});
</script>
<style src="@/assets/css/newdesign.scss" lang="scss" scoped></style>
